import { UseQueryOptions } from '@tanstack/react-query'
import { useMemo } from 'react'
import { CartProps } from '~/@types/cart'
import { SHOPIFY_CUSTOM_ATTRIBUTES } from '~/lib/constants'
import { isSmartCheckoutWidgetsCoupon } from '~/lib/custom-product'
import { PRISMIC_TYPES } from '~/lib/prismic-types'

import { useGetCart as useAbstractGetCart } from '@unlikelystudio/react-ecommerce-hooks'

import useLocale from '~/hooks/useLocale'

import serializeCart from '~/data/cart-data/serialize-cart'
import serializeCartProducts from '~/data/cart-data/serialize-cart-products'

export type Cart = NonNullable<ReturnType<typeof useAbstractGetCart>['data']>

export function getCartTypeFromProducts(
  products: CartProps['products'],
): string | undefined {
  const isFragrance = products?.find(
    (item) =>
      item?.attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.prismicType] ===
        PRISMIC_TYPES.PRODUCT_FRAGRANCE ||
      item?.attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.prismicType] ===
        PRISMIC_TYPES.PRODUCT_SETS,
  )
  const isFashion = products?.find(
    (item) =>
      item?.attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.prismicType] ===
      PRISMIC_TYPES.PRODUCT_FASHION,
  )

  if (isFashion && isFragrance) return 'order_mixed'
  else if (isFragrance) return 'order_fragrance'
  else if (isFashion) return 'order_fashion'
  else return undefined
}

export function getTrackingDataFromProducts(products) {
  const mappedAttributes =
    products
      ?.map(({ attributes }) => {
        const { _trackingData: trackingData } = attributes

        if (!trackingData) {
          return null
        }

        const parsedData = JSON.parse(trackingData)

        return parsedData
      })
      ?.filter(Boolean) ?? []

  return JSON.stringify(mappedAttributes)
}

export function serializeCartFromPayload(
  cartPayload,
  locale: string,
): CartProps {
  const cart = serializeCart(cartPayload, locale)
  const products = serializeCartProducts(cartPayload?.lines, locale)

  // Remove coupons from Smart Checkout Widgets from products cart
  const productsFiltered = products?.filter(
    (item) => !isSmartCheckoutWidgetsCoupon(item),
  )

  const serializedCart = {
    ...cart,
    products: productsFiltered,
  }

  return serializedCart
}

export default function useGetCart(
  mutationOptions?: UseQueryOptions<any, any, any>,
) {
  const locale = useLocale()

  const { data: cartPayload } = useAbstractGetCart({
    refetchOnMount: false,
    onSuccess: (data) => {
      mutationOptions?.onSuccess && mutationOptions.onSuccess(data)
    },
  })

  const serializedCart = useMemo(
    () => serializeCartFromPayload(cartPayload, locale),
    [cartPayload],
  )

  return {
    cart: serializedCart,
  }
}
