export enum GlobalTextPreset {
  // HafferSQ Rebrand
  /** Titles */
  Title10HafferSemiBold = 'title-10-haffer-semibold',
  Title10_14HafferSemiBold = 'title-10-14-haffer-semibold',
  Title12HafferSemiBold = 'title-12-haffer-semibold',
  Title12_13HafferSemiBold = 'title-12-13-haffer-semibold',
  Title12_14HafferSemiBold = 'title-12-14-haffer-semibold',
  Title12_14Haffer = 'title-12-14-haffer',
  Title14HafferSemiBold = 'title-14-haffer-semibold',
  Title14Haffer = 'title-14-haffer',
  Title14_18HafferSemiBold = 'title-14-18-haffer-semibold',
  Title14_24HafferSemiBold = 'title-14-24-haffer-semibold',
  Title18HafferSemiBold = 'title-18-haffer-semibold',
  Title18Haffer = 'title-18-haffer',
  Title18_24HafferSemiBold = 'title-18-24-haffer-semibold',
  Title18_24Haffer = 'title-18-24-haffer',
  Title18_35HafferSemiBold = 'title-18-35-haffer-semibold',
  Title24HafferSemiBold = 'title-24-haffer-semibold',
  Title25_35HafferSemiBold = 'title-25-35-haffer-semibold',
  Title30_35HafferSemiBold = 'title-30-35-haffer-semibold',
  Title30_60HafferSemiBold = 'title-30-60-haffer-semibold',
  Title45HafferSemiBold = 'title-45-haffer-semibold',
  Title45_60HafferSemiBold = 'title-45-60-haffer-semibold',
  Title80_60HafferSemiBold = 'title-80-60-haffer-semibold',
  Titlefluid30_80HafferSemiBold = 'title-fluid-30-80-haffer-semibold',
  Titlefluid45_80HafferSemiBold = 'title-fluid-45-80-haffer-semibold',
  Titlefluid80_160HafferSemiBold = 'title-fluid-80-160-haffer-semibold',
  Titlefluid80_330HafferSemiBold = 'title-fluid-80-330-haffer-semibold',
  Titlefluid50_140HafferSemiBold = 'title-fluid-50-140-haffer-semibold',
  Titlefluid45_150HafferSemiBold = 'title-fluid-45-150-haffer-semibold',

  /** CTAs */
  Cta10Haffer = 'cta-10-haffer',
  Cta11Haffer = 'cta-11-haffer',
  Cta10HafferSemiBold = 'cta-10-haffer-semibold',
  Cta10_12Haffer = 'cta-10-12-haffer',
  Cta10_12HafferSemiBold = 'cta-10-12-haffer-semibold',
  Cta12Haffer = 'cta-12-haffer',
  Cta12HafferSemiBold = 'cta-12-haffer-semibold',
  Cta14Haffer = 'cta-14-haffer',
  Cta14HafferSemiBold = 'cta-14-haffer-semibold',
  Cta12_14Haffer = 'cta-12-14-haffer',
  Cta12_13HafferSemiBold = 'cta-12-13-haffer-semibold',
  Cta12_14HafferSemiBold = 'cta-12-14-haffer-semibold',
  Cta14_16Haffer = 'cta-14-16-haffer',

  /** Labels */
  Label10Haffer = 'label-10-haffer',
  Label10_12Haffer = 'label-10-12-haffer',
  Label10_14Haffer = 'label-10-14-haffer',
  Label10HafferSemiBold = 'label-10-haffer-semibold',
  Label10_12HafferSemiBold = 'label-10-12-haffer-semibold',
  Label12HafferSemiBold = 'label-12-haffer-semibold',
  Label12Haffer = 'label-12-haffer',
  Label12_13Haffer = 'label-12-13-haffer',
  Label12_14Haffer = 'label-12-14-haffer',
  Label12_14HafferSemibold = 'label-12-14-haffer-semibold',

  /** Inputs */
  Input12Haffer = 'input-12-haffer',
  Input12_14Haffer = 'input-12-14-haffer',

  /** Texts */
  Text12Haffer = 'text-12-haffer',
  Text14Haffer = 'text-14-haffer',
  Text12_14Haffer = 'text-12-14-haffer',
  Text12_14_1Haffer = 'text-12-14-1-haffer',
  Text14_16Haffer = 'text-14-16-haffer',

  /** Rich Texts */
  DefaultRichText = 'rich-text',
}
