import { CartProduct } from '~/@types/cart'
import { SHOPIFY_CUSTOM_ATTRIBUTES } from '~/lib/constants'
import { PRISMIC_TYPES } from '~/lib/prismic-types'

/**
 * `isProductFashion` returns true if the product's custom attribute `prismicType` is equal to
 * `PRISMIC_TYPES.PRODUCT_FASHION`
 * @param {CartProduct} product - CartProduct - this is the product object that is passed to the
 * function
 * @returns A boolean
 */
export function isProductFashion(product: CartProduct) {
  return (
    product?.attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.prismicType] ===
    PRISMIC_TYPES.PRODUCT_FASHION ?? false
  )
}

/**
 * `isProductFragrance` returns true if the product's custom attribute `prismicType` is equal to
 * `PRISMIC_TYPES.PRODUCT_FRAGRANCE`
 * @param {CartProduct} product - CartProduct - this is the product object that is passed into the
 * function.
 * @returns A boolean value
 */
export function isProductFragrance(product: CartProduct) {
  return (
    product?.attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.prismicType] ===
    PRISMIC_TYPES.PRODUCT_FRAGRANCE ?? false
  )
}

/**
 * `isProductGift` returns true if the product has a custom attribute called `gift` with a value of
 * `true`
 * @param {CartProduct} product - CartProduct - this is the product object that is passed to the
 * function.
 * @returns A boolean value
 */

export function isProductGift(product: CartProduct) {
  return (
    product?.attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.gift] === 'true' ??
    false
  )
}

/**
 * `isProductSample` returns true if the product is a sample, false otherwise.
 * @param {CartProduct} product - CartProduct - this is the product object that is passed to the
 * function.
 * @returns A boolean value
 */
export function isProductSample(product: CartProduct) {
  return (
    product?.attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.sample] === 'true' ??
    false
  )
}

/**
 * `isProductSets` returns true if the product has a custom attribute called `sets` with a value of
 * `true`
 * @param {CartProduct} product - CartProduct - this is the product object that is passed to the
 * function.
 * @returns A function that takes in a product and returns a boolean.
 */
export function isProductSets(product: CartProduct) {
  return (
    product?.attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.sets] === 'true' ??
    false
  )
}

/**
 * This TypeScript function checks if a product in a shopping cart is a pouch product based on its
 * custom attributes.
 * @param {CartProduct} product - The `product` parameter is an object that represents a product in a
 * shopping cart. It likely contains information such as the product's name, price, quantity, and any
 * custom attributes associated with it.
 * @returns The function `isProductPouch` takes a `CartProduct` object as input and returns a boolean
 * value. It checks if the `attributes` property of the `CartProduct` object has a key that
 * matches the `SHOPIFY_CUSTOM_ATTRIBUTES.pouch` constant and if its value is equal to the string
 * `'true'`. If the condition is true, the function returns `true
 */
export function isProductPouch(product: CartProduct) {
  return (
    product?.attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.pouch] === 'true' ??
    false
  )
}

export function isProductSetsLinked(product: CartProduct) {
  return (
    (product?.attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.sets] === 'true' &&
      !product?.attributes?.[
      SHOPIFY_CUSTOM_ATTRIBUTES.productLinkedId
      ]) ??
    false
  )
}

/**
 * `isProductSampleFromProduct` returns true if the product is a sample auto added from a product page
 * @param {CartProduct} product - CartProduct - this is the product that you're checking to see if it's
 * a sample from another product.
 * @returns A boolean value
 */
export function isProductSampleFromProduct(product: CartProduct) {
  return (
    product?.attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.sampleFromProduct] ===
    'true' ?? false
  )
}

/**
 * It returns true if the product has a custom attribute called `realProduct` and the value of that
 * attribute is `true`
 * @param {CartProduct} product - CartProduct - this is the product object that is passed to the
 * function.
 * @returns A boolean value
 */
export function isProductRealProduct(product: CartProduct) {
  return (
    product?.attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.realProduct] ===
    'true' ?? false
  )
}

/**
 * The function checks if a product is a gift card based on its custom attributes.
 * @param {CartProduct} product - The `product` parameter is an object that represents a product in a
 * shopping cart. It likely has properties such as `id`, `name`, `price`, `quantity`, and
 * `attributes`.
 * @returns a boolean value. It will return true if the `product` object has a `attributes`
 * property and the value of `product.attributes[SHOPIFY_CUSTOM_ATTRIBUTES.prismicType]` is equal
 * to 'gift_card'. Otherwise, it will return false.
 */
export function isProductGiftCard(product: CartProduct) {
  return (
    product?.attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.prismicType] ===
    'gift_card' ?? false
  )
}


export function isSmartCheckoutWidgetsCoupon(product: CartProduct) {
  return (
    Boolean(product?.attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.coupon])
  )
}