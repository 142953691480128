import { createContext, useContext, useMemo, useState } from 'react'

import { VenusSliceWrapperProps } from '~/components/UI/VenusSliceWrapper'

export const VenusThemeProductButtonContext =
  createContext<VenusProductButtonThemeValueType>({})

interface VenusThemeProductButtonProviderProps {
  children: JSX.Element | JSX.Element[]
}

export interface VenusProductButtonThemeValueType {
  themeProductButton?: VenusSliceWrapperProps['themeProductButton']
  setThemeProductButton?: (
    value: VenusSliceWrapperProps['themeProductButton'],
  ) => void
}

export default function VenusThemeProductButtonProvider({
  children,
}: VenusThemeProductButtonProviderProps) {
  const [themeProductButton, setThemeProductButton] =
    useState<VenusSliceWrapperProps['themeProductButton']>('light')

  const processedValue: VenusProductButtonThemeValueType = useMemo(
    () => ({
      themeProductButton,
      setThemeProductButton,
    }),
    [themeProductButton, setThemeProductButton],
  )

  return (
    <VenusThemeProductButtonContext.Provider value={processedValue}>
      {children}
    </VenusThemeProductButtonContext.Provider>
  )
}

export function useVenusThemeProductButtonContext() {
  return useContext(VenusThemeProductButtonContext)
}
