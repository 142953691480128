import React, { createContext, ReactNode, useContext, useMemo } from 'react'

type SliceProviderContext = SliceProviderValue & { name: string }

const Context = createContext<SliceProviderContext>({
  type: null,
  name: null,
  isPromotion: false,
  isProduct: false,
  position: null,
  promotionPosition: null,
  productPosition: null,
  customData: [],
})

export function useSliceProvider() {
  return useContext(Context)
}

interface SliceProviderValue {
  type?: string
  isPromotion?: boolean
  isProduct?: boolean
  position?: number
  promotionPosition?: number
  productPosition?: number
  customData?: Record<string, string>[]
}

export interface SliceProviderProps {
  children?: ReactNode | ReactNode[]
  value?: SliceProviderValue
}

function SliceProvider({ children, value }: SliceProviderProps) {
  const providerValue = useMemo<SliceProviderContext>(
    () => ({
      ...value,
      name: value?.type.replace(/_/g, ' '),
    }),
    [value],
  )

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}

SliceProvider.defaultProps = {}

export default SliceProvider
