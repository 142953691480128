import { createContext, useContext, useMemo, useState } from 'react'

export const VenusCurrentNavigationSectionContext = createContext<ValueType>({})

interface VenusCurrentNavigationSectionProviderProps {
  children: JSX.Element | JSX.Element[]
}

export interface ValueType {
  currentNavigationSection?: string
  setCurrentNavigationSection?: (value: string) => void
}

export default function VenusCurrentNavigationSectionProvider({
  children,
}: VenusCurrentNavigationSectionProviderProps) {
  const [currentNavigationSection, setCurrentNavigationSection] =
    useState<string>(null)

  const processedValue: ValueType = useMemo(
    () => ({
      currentNavigationSection,
      setCurrentNavigationSection,
    }),
    [currentNavigationSection, setCurrentNavigationSection],
  )

  return (
    <VenusCurrentNavigationSectionContext.Provider value={processedValue}>
      {children}
    </VenusCurrentNavigationSectionContext.Provider>
  )
}

export function useVenusCurrentNavigationSectionContext() {
  return useContext(VenusCurrentNavigationSectionContext)
}
