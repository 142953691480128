import classnames from 'classnames/bind'
import { m } from 'framer-motion'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'

import { useStyle } from '~/providers/StyleProvider'
import { useVenusCurrentNavigationSectionContext } from '~/providers/VenusCurrentNavigationSection'
import { useVenusThemeBackgroundNavigationContext } from '~/providers/VenusThemeBackgroundNavigationProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type VenusNavigationItemProps = {
  label: RichTextBlocks
  sectionId: string
}[]

export interface VenusNavigationComponentProps {
  className?: string
  items: VenusNavigationItemProps
}

function VenusNavigationComponent({
  className,
  items,
}: VenusNavigationComponentProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const { currentNavigationSection } = useVenusCurrentNavigationSectionContext()
  const { themeBackgroundNavigation } =
    useVenusThemeBackgroundNavigationContext()

  const anchorStyle = useStyle({
    textPreset: GlobalTextPreset.Cta11Haffer,
    color: GlobalThemeColors.White,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <div className={cx(css.VenusNavigationComponent, gridStyle, className)}>
      <m.div className={cx(css.background, css[themeBackgroundNavigation])} />
      <div className={css.wrapper}>
        {items?.map((item, i) => {
          return (
            <a
              className={cx(css.anchor, anchorStyle, {
                [css.active]: currentNavigationSection === item.sectionId,
              })}
              key={`anchor-${i}`}
              href={`#${item.sectionId}`}>
              <RichText render={item.label} />
            </a>
          )
        })}
      </div>
    </div>
  )
}

export default VenusNavigationComponent
