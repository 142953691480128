import { iconFromReactSvg } from '~/components/Abstracts/Icons'

import Account from './account.svg'
import ArrowLeft from './arrow-left.svg'
import ArrowRight from './arrow-right.svg'
import ArrowSelect from './arrow-select.svg'
import Bag from './bag.svg'
import BigArrowLeft from './big-arrow-left.svg'
import Cart from './cart.svg'
import Check from './check.svg'
import CheckmarkSelect from './checkmarkSelect.svg'
import CrossMarket from './crossMarket.svg'
import Logout from './logout.svg'
import MobileClose from './mobile-close-icon.svg'
import MobileMenu from './mobile-menu-icon.svg'
import MobileStoreLocator from './mobile-store-locator.svg'
import PlayRounded from './play-rounded.svg'
import Star from './star.svg'
import StoreLocator from './store-locator.svg'
import css from './styles.module.scss'
import VenusLogo from './venus-logo.svg'
import VenusScrollArrow from './venus-scroll-arrow.svg'

export const PlayRoundedIcon = iconFromReactSvg(
  PlayRounded,
  css.PlayRoundedIcon,
)

export const BigArrowLeftIcon = iconFromReactSvg(
  BigArrowLeft,
  css.BigArrowLeftIcon,
)

export const BigArrowRightIcon = iconFromReactSvg(
  BigArrowLeft,
  css.BigArrowRightIcon,
)

export const ArrowSelectIcon = iconFromReactSvg(ArrowSelect)

export const ArrowLeftIcon = iconFromReactSvg(ArrowLeft, css.ArrowLeftIcon)

export const ArrowRightIcon = iconFromReactSvg(ArrowLeft, css.ArrowRightIcon)

export const CrossMarketIcon = iconFromReactSvg(
  CrossMarket,
  css.CrossMarketIcon,
)

export const CheckmarkSelectIcon = iconFromReactSvg(
  CheckmarkSelect,
  css.CheckmarkSelectIcon,
)

export const CheckIcon = iconFromReactSvg(Check, css.Check)

export const StoreLocatorIcon = iconFromReactSvg(StoreLocator, css.StoreLocator)

export const MobileStoreLocatorIcon = iconFromReactSvg(
  MobileStoreLocator,
  css.MobileStoreLocator,
)

export const StarIcon = iconFromReactSvg(Star, css.StarIcon)

export const AccountIcon = iconFromReactSvg(Account, css.AccountIcon)

export const CartIcon = iconFromReactSvg(Cart, css.CartIcon)

export const MobileCloseIcon = iconFromReactSvg(
  MobileClose,
  css.MobileCloseIcon,
)

export const LogoutIcon = iconFromReactSvg(Logout, css.LogoutIcon)

export const ArrowRightVenusIcon = iconFromReactSvg(
  ArrowRight,
  css.ArrowRightVenusIcon,
)
export const BagIcon = iconFromReactSvg(Bag, css.BagIcon)
export const MobileMenuIcon = iconFromReactSvg(MobileMenu, css.MobileMenuIcon)
export const VenusScrollArrowIcon = iconFromReactSvg(
  VenusScrollArrow,
  css.VenusScrollArrow,
)

export const VenusLogoIcon = iconFromReactSvg(VenusLogo, css.VenusLogo)
