import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useMeasure } from 'react-use'
import { GlobalThemeColors } from '~/@types/colors'

import Navigation from '~/components/Navigation/Navigation'
import { NavigationProps } from '~/components/Navigation/Navigation/types'
import VenusNavigationComponent, {
  VenusNavigationItemProps,
} from '~/components/UI/VenusNavigationComponent'

import { useVenusThemeBackgroundNavigationContext } from '~/providers/VenusThemeBackgroundNavigationProvider'

import useScrollDirection from '~/hooks/useScrollDirection'

import css from './styles.module.scss'

const cx = classnames.bind(css)

type TVenusNavigation = {
  venusNavigationItems: VenusNavigationItemProps
} & NavigationProps

const DURATION = 0.5

function VenusNavigation({
  className,
  banner,
  isDisabled,
  venusNavigationItems,
  ...rest
}: TVenusNavigation) {
  const scrollDirection = useScrollDirection()
  const [venusRef, { height: venusHeightMeasure }] = useMeasure()
  const { themeBackgroundNavigation } =
    useVenusThemeBackgroundNavigationContext()

  const [mainNavigationDisplay, setMainNavigationDisplay] = useState(
    scrollDirection === 'up',
  )
  const [venusNavigation, setVenusNavigation] = useState(
    scrollDirection === 'down',
  )

  const venusHeight = !venusHeightMeasure ? 71 : venusHeightMeasure

  useEffect(() => {
    if (scrollDirection === 'up' || scrollDirection === null) {
      setMainNavigationDisplay(true)
      setVenusNavigation(false)
    }
    if (scrollDirection === 'down') {
      setMainNavigationDisplay(false)
      setVenusNavigation(true)
    }
  }, [scrollDirection])

  const mainNavigationAnimation = {
    initial: {
      y: -venusHeight,
      opacity: 0,
    },
    animate: {
      y: mainNavigationDisplay ? 0 : -venusHeight,
      opacity: mainNavigationDisplay ? 1 : 0,
      transition: {
        duration: DURATION,
      },
    },
    exit: {
      y: -venusHeight,
      opacity: 0,
    },
  }

  const venusNavigationAnimation = {
    initial: {
      y: -venusHeight,
      opacity: 0,
    },
    animate: {
      y: venusNavigation ? 0 : -venusHeight,
      opacity: venusNavigation ? 1 : 0,
      transition: {
        duration: DURATION,
      },
    },
    exit: {
      y: -venusHeight,
      opacity: 0,
    },
  }

  return (
    <div className={cx(css.VenusNavigation, className)}>
      <AnimatePresence>
        {mainNavigationDisplay && (
          <m.div
            className={cx(css.navigation)}
            initial={mainNavigationAnimation.initial}
            animate={mainNavigationAnimation.animate}
            exit={mainNavigationAnimation.exit}>
            <Navigation
              className={cx(css.mainNavigation, css[themeBackgroundNavigation])}
              theme={GlobalThemeColors.White}
              banner={banner}
              {...rest}
              isDisabled={isDisabled}
              hasAnimation={false}
            />
          </m.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {venusNavigation && (
          <m.div
            ref={venusRef}
            className={css.navigation}
            initial={venusNavigationAnimation.initial}
            animate={venusNavigationAnimation.animate}
            exit={venusNavigationAnimation.exit}>
            <VenusNavigationComponent items={venusNavigationItems} />
          </m.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default VenusNavigation
