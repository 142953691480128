import classnames from 'classnames'
import { default as NextLink, LinkProps as NextLinkProps } from 'next/link'
import { useRouter } from 'next/router'
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  forwardRef,
  MouseEvent,
  PropsWithChildren,
  Ref,
} from 'react'

export interface LinkProps
  extends Omit<NextLinkProps, 'href'>,
    PropsWithChildren {
  className?: string
  activeClassName?: string
  isExternal?: boolean
  href?: NextLinkProps['href']
  rel?: string
  target?: string
  obfuscate?: boolean
  style?: React.CSSProperties
}

function LinkEntity(
  {
    className,
    activeClassName,
    href,
    children,
    isExternal,
    obfuscate,
    prefetch,
    legacyBehavior,
    ...rest
  }: LinkProps,
  ref: Ref<HTMLButtonElement | HTMLAnchorElement>,
): JSX.Element {
  const router = useRouter()
  const isActive = !isExternal && href === router?.asPath

  if (obfuscate) {
    const onClick = (e: MouseEvent<HTMLElement>) => {
      rest?.onClick?.(e as MouseEvent<HTMLAnchorElement>)
      href && router.push(href as string)
    }

    return (
      <button
        ref={ref as Ref<HTMLButtonElement>}
        className={classnames(className, {
          ...(activeClassName && {
            [activeClassName]: activeClassName && isActive,
          }),
        })}
        {...(rest as unknown as Omit<
          ButtonHTMLAttributes<HTMLButtonElement>,
          'href'
        >)}
        onClick={onClick}>
        {children}
      </button>
    )
  }

  if (href) {
    if (isExternal) {
      return (
        <a
          ref={ref as Ref<HTMLAnchorElement>}
          className={className}
          rel="noreferrer noopener"
          href={href as string}
          {...(rest as unknown as Omit<
            AnchorHTMLAttributes<HTMLAnchorElement>,
            'href'
          >)}>
          {children}
        </a>
      )
    }

    return (
      <NextLink
        href={href}
        ref={ref as Ref<HTMLAnchorElement>}
        className={classnames(className, {
          ...(activeClassName && {
            [activeClassName]: activeClassName && isActive,
          }),
        })}
        {...(rest as unknown as Omit<NextLinkProps, 'href'>)}>
        <>{children}</>
      </NextLink>
    )
  }

  return (
    <button
      ref={ref as Ref<HTMLButtonElement>}
      className={classnames(className, {
        ...(activeClassName && {
          [activeClassName]: activeClassName && isActive,
        }),
      })}
      {...(rest as unknown as Omit<
        ButtonHTMLAttributes<HTMLButtonElement>,
        'href'
      >)}>
      <>
        {href}
        {children}
      </>
    </button>
  )
}

export default forwardRef(LinkEntity)
