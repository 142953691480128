import { useViewportScroll } from 'framer-motion';
import { useEffect, useState } from 'react';

const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState(null);
  const { scrollY } = useViewportScroll();
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    return scrollY.onChange((latest) => {
      if (latest > lastScrollY) {
        setScrollDirection('down');
      } else if (latest < lastScrollY) {
        setScrollDirection('up');
      }
      setLastScrollY(latest);
    });
  }, [scrollY, lastScrollY]);

  return scrollDirection;
};

export default useScrollDirection;
