import { createContext, useContext, useMemo, useState } from 'react'

import { VenusSliceWrapperProps } from '~/components/UI/VenusSliceWrapper'

export const VenusThemeBackgroundNavigationContext =
  createContext<VenusNavigationThemeValueType>({})

interface VenusThemeBackgroundNavigationProviderProps {
  children: JSX.Element | JSX.Element[]
}

export interface VenusNavigationThemeValueType {
  themeBackgroundNavigation?: VenusSliceWrapperProps['themeBackgroundNavigation']
  setThemeBackgroundNavigation?: (
    value: VenusSliceWrapperProps['themeBackgroundNavigation'],
  ) => void
}

export default function VenusThemeBackgroundNavigationProvider({
  children,
}: VenusThemeBackgroundNavigationProviderProps) {
  const [themeBackgroundNavigation, setThemeBackgroundNavigation] =
    useState<VenusSliceWrapperProps['themeBackgroundNavigation']>('none')

  const processedValue: VenusNavigationThemeValueType = useMemo(
    () => ({
      themeBackgroundNavigation,
      setThemeBackgroundNavigation,
    }),
    [themeBackgroundNavigation, setThemeBackgroundNavigation],
  )

  return (
    <VenusThemeBackgroundNavigationContext.Provider value={processedValue}>
      {children}
    </VenusThemeBackgroundNavigationContext.Provider>
  )
}

export function useVenusThemeBackgroundNavigationContext() {
  return useContext(VenusThemeBackgroundNavigationContext)
}
